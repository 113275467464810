import cx from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

const ListSeparator: FunctionComponent<{
  as?: keyof JSX.IntrinsicElements;
  children: string | ReactNode;
  vehiclePickerListItem?: boolean;
}> = ({ as: As = 'li', children, vehiclePickerListItem = false }) => {
  return (
    <As
      className={cx(
        'mt-11 first:mt-0',
        'pb-3',
        'lg:flex',
        'w-full',
        'text-[38px] leading-9',
        'font-bold uppercase',
        'select-none',
        'text-bparts-100',
        vehiclePickerListItem ? 'py-3.5 px-4' : 'lg:mx-3 p-3.5'
      )}
      role="separator"
    >
      {children}
    </As>
  );
};

export default ListSeparator;
