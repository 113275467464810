import { useRouter } from 'next/router';
import { useMemo } from 'react';

import useLinkProps from '../use-link-props';

type UseBuildListProps = {
  brands: Array<TBrand | TModel | TVersion>;
  search: string;
};

type Separator = {
  type: 'separator';
  children: { text: string };
};

type Brand = {
  type: 'brand';
  children: {
    brand: TBrand;
    link: ReturnType<ReturnType<typeof useLinkProps>>;
  };
};

type UseBuildListReturn = Array<Separator | Brand>;
type UseBuildListNewReturn = Array<Array<Separator | Brand>>;

const useBuildList = ({
  brands,
  search,
}: UseBuildListProps): UseBuildListNewReturn => {
  const { query } = useRouter();
  const getLinkProps = useLinkProps();
  const hasPart = query.category && query.subCategory;

  const filteredBrands: Array<TBrand | TModel | TVersion> = useMemo(() => {
    if (search) {
      return brands.filter((brand: TBrand | TModel | TVersion) =>
        brand.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    return brands;
  }, [search, brands]);

  return filteredBrands.reduce(
    (
      final: {
        values: UseBuildListNewReturn;
        lastLetter: string;
        newValueToAdd: UseBuildListReturn;
      },
      brand: TBrand,
      currentIndex: number
    ) => {
      const letter = brand.name.charAt(0);

      //first letter
      if (final.lastLetter === '') {
        final.lastLetter = letter;
        final.newValueToAdd.push({
          type: 'separator',
          children: { text: letter },
        });
      }

      //different letter
      if (letter !== final.lastLetter) {
        final.values.push(final.newValueToAdd);
        final.newValueToAdd = [];

        final.lastLetter = letter;
        final.newValueToAdd.push({
          type: 'separator',
          children: { text: letter },
        });
      }

      final.newValueToAdd.push({
        type: 'brand',
        children: {
          brand,
          link: hasPart
            ? getLinkProps('models-with-part', {
                brand: brand.slug,
                category: query.category as string,
                subCategory: query.subCategory as string,
              })
            : getLinkProps('models', {
                brand: brand.slug,
              }),
        },
      });

      //last letter
      if (currentIndex === filteredBrands.length - 1) {
        final.values.push(final.newValueToAdd);
      }

      return final;
    },
    {
      values: [],
      lastLetter: '',
      newValueToAdd: [],
    }
  ).values;
};

export default useBuildList;
