import cx from 'classnames';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';

import useLinkProps from '../../../../hooks/use-link-props';
import Select, {
  CustomDownChevron,
  CustomOptionVehicle,
} from '../../../ui/inputs/custom-selector';

const HistoryVehicles: FunctionComponent<{
  vehicles: TCarCompleteInfo[] | undefined;
  isLoading: boolean;
}> = ({ vehicles, isLoading }) => {
  const { t } = useTranslation();
  const getLink = useLinkProps();
  const { query, push } = useRouter();

  if (isLoading) {
    return <div className="lg:min-w-[360px]"></div>;
  }

  if (vehicles?.length === 0) {
    return null;
  }

  const hasPart = query.category && query.subCategory;

  return (
    <div
      className={cx(
        'flex-1',
        'w-full',
        'flex',
        'flex-col',
        'justify-between',
        'lg:pr-0'
      )}
    >
      <div>
        <Select
          options={vehicles?.map((car) => {
            const info = car.version || car.model;

            return {
              slug: info.slug,
              brand: {
                label: info.brand_name,
                logo: info.logo_url,
              },
              model: {
                label: info.model_name,
              },
              ...(info.version_name && {
                version: { label: info.version_name },
              }),
            };
          })}
          components={{
            Option: CustomOptionVehicle,
            DropdownIndicator: CustomDownChevron,
          }}
          onChange={(value) => {
            const { href, as } = hasPart
              ? getLink('products', {
                  category: query.category as string,
                  subCategory: query.subCategory as string,
                  vehicle: value?.slug,
                })
              : getLink('categories-with-vehicle', {
                  category: value?.slug,
                });
            push(href, as, { shallow: true });
          }}
          placeholder={
            vehicles.length === 1
              ? t('common:saved_cars.one', { count: vehicles.length })
              : t('common:saved_cars.more', {
                  count: vehicles.length,
                })
          }
        />
      </div>
    </div>
  );
};

export default HistoryVehicles;
