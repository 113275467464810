import cx from 'classnames';
import { FunctionComponent, ReactNode, useRef } from 'react';

import DisableFocusProvider from '../../../providers/disable-focus';

import classes from './collapsible-list.module.css';

const Collapsable: FunctionComponent<{
  content: ReactNode;
  active: boolean;
  id: string;
  closeCollapsable?: () => void;
}> = ({ content, active, id, closeCollapsable }) => {
  const ref = useRef<HTMLDivElement>(null);
  
  return (
    <DisableFocusProvider value={!active}>
      <div
        id={id}
        className={cx(
          {'h-0': !active },
          classes.collaspible,
          'overflow-hidden border-b border-neutral-30'
        )}
      >
        <div
          ref={ref}
          className="relative grid gap-6"
          role="button"
          onClick={() => {
            closeCollapsable && closeCollapsable();
          }}
          data-cy="collapsable-content"
        >
          {content}
        </div>
      </div>
    </DisableFocusProvider>
  );
};

export default Collapsable;
