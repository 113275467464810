import useTranslation from 'next-translate/useTranslation';
import { Dispatch, SetStateAction, useRef } from 'react';

import useIsMobile from '../../../../../hooks/use-is-mobile';
import {
  brandAtom,
  modelAtom,
  versionAtom,
} from '../../../../../hooks/use-picker-selection';
import { useAppContext } from '../../../../providers/app-context';
import { useAtom } from 'jotai';
import {
  categoryInformation,
  plateOrKbaForm,
  vehiclePickerOpen,
} from '../../../../../state/vehicle';
import Trans from 'next-translate/Trans';
import FeaturedLink from '../../../../ui/links/featured';

type SearchByPlateSectionProps = {
  selection: { category: TCategorySelection };
  hasSelection: any;
  searchByPlateAvailable?: boolean;
};

const SearchByPlateSection = ({
  selection,
  hasSelection,
  searchByPlateAvailable,
}: SearchByPlateSectionProps) => {
  const { isPlate, isKBA } = useAppContext();
  const [, setVehiclePicker] = useAtom(vehiclePickerOpen);
  const [, setCategoryInformation] = useAtom(categoryInformation);
  const [, setBrandAtom] = useAtom(brandAtom);
  const [, setModelAtom] = useAtom(modelAtom);
  const [, setVersionAtom] = useAtom(versionAtom);
  const [, setPlateOrKbaForm] = useAtom(plateOrKbaForm);

  const openVehiclePicker = () => {
    setVehiclePicker((prevValue) => !prevValue);
    setPlateOrKbaForm(true);
    setBrandAtom(null);
    setModelAtom(null);
    setVersionAtom(null);

    if (hasSelection) {
      setCategoryInformation(selection?.category);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {(isPlate || isKBA) && searchByPlateAvailable && (
        <div
          key={'vehiclePicker'}
          className={
            'w-full container m-auto pt-12 text-center text-base lg:text-xl z-1'
          }
        >
          <p>
            <Trans
              i18nKey={'common:vehiclePicker'}
              components={{
                vehiclePicker: (
                  <FeaturedLink
                    variant="blue"
                    className="lg:text-xl max-lg:text-base !z-1"
                    onClick={openVehiclePicker}
                  >
                    {t('vehicle-picker:insertPlateSearch')}
                  </FeaturedLink>
                ),
              }}
            />
          </p>
        </div>
      )}
    </>
  );
};

export default SearchByPlateSection;
