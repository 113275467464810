import cx from 'classnames';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

import Button from '../../button';
import BPImage from '../../image';

const FloatScroll: FunctionComponent<{
  handleOnScroll: () => void;
  minWitdhToDisplayScroll?: number;
  isOnlyMobile?: boolean;
  selectYPositon?: 'top' | 'bottom';
  selectXPositon?: 'left' | 'right';
}> = ({
  handleOnScroll,
  minWitdhToDisplayScroll = 300,
  selectYPositon = 'bottom',
  selectXPositon = 'right',
  isOnlyMobile = true,
}) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > minWitdhToDisplayScroll) {
        setVisible(true);
      } else if (scrolled <= minWitdhToDisplayScroll) {
        setVisible(false);
      }
    };
    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, [minWitdhToDisplayScroll]);

  return (
    <>
      <Button
        variant="ROUNDED"
        isFullWidth={false}
        className={cx(
          'text-right w-fit -mt-12',
          isOnlyMobile ? 'max-lg:sticky' : 'sticky',
          selectYPositon === 'bottom' ? 'bottom-6' : 'top-0 z-50',
          selectXPositon === 'right' ? 'left-[100%]' : 'left-4',
          {
            hidden: !visible,
          }
        )}
        onClick={handleOnScroll}
      >
        <BPImage
          src={`${process.env.PUBLIC_PREFIX}/svg/icons/back-to-top.svg`}
          width={48}
          height={48}
          className={cx('h-12', 'w-12')}
        />
      </Button>
    </>
  );
};

export default FloatScroll;
