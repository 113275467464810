import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { Dispatch, SetStateAction, useRef } from 'react';

import useIsMobile from '../../../../../hooks/use-is-mobile';
import { useGetVehicles } from '../../../../../hooks/use-picker-selection';
import SearchInput from '../../../v2/vehicle/search-input';
import HistoryVehicles from '../history-vehicles';

type BeforeListProps = {
  search: string;
  isMobileView?: boolean;
  setSearch: Dispatch<SetStateAction<string>>;
  savedCarsAvailable?: boolean;
};

const BeforeList = ({
  search,
  setSearch,
  isMobileView,
  savedCarsAvailable = true,
}: BeforeListProps) => {
  const ref = useRef();
  const isMobile = useIsMobile() || isMobileView;

  const { t } = useTranslation();

  const { vehicles, isLoading } = useGetVehicles();

  return (
    <div className="">
      {!isMobile && (
        <section className=" bg-white top-0">
          <section
            className={cx(
              'w-full m-auto',
              'flex flex-colgap-2',
              'items-center lg:items-start',
              'container m-auto flex flex-col lg:flex-row gap-2 lg:gap-12 max-lg:px-4'
            )}
          >
            {vehicles && vehicles.length > 0 && savedCarsAvailable && (
              <div className={cx('w-1/3')}>
                <HistoryVehicles vehicles={vehicles} isLoading={isLoading} />
              </div>
            )}
            <div
              className={cx(
                'lg:mt-0',
                vehicles && vehicles.length > 0 ? 'w-2/3' : 'w-full',
                'w-full',
                { hidden: isMobile }
              )}
              ref={ref}
            >
              <SearchInput
                search={search}
                placeholder={t('vehicle:brands.searchByBrand')}
                setSearch={setSearch}
                isFullWith={true}
              />
            </div>
          </section>
        </section>
      )}
      {isMobile && vehicles && vehicles.length > 0 && savedCarsAvailable && (
        <div className={cx('px-4 pt-4 max-lg:pt-0 w-full relative !z-[11] ')}>
          <HistoryVehicles vehicles={vehicles} isLoading={isLoading} />
        </div>
      )}
      {isMobile && (
        <div
          className={cx('px-4', 'lg:flex-1', ' bg-white top-0 z-10', {
            'w-full': search,
          })}
        >
          <SearchInput
            search={search}
            placeholder={t('vehicle:brands.searchByBrand')}
            setSearch={setSearch}
            isFullWith={true}
          />
        </div>
      )}
    </div>
  );
};

export default BeforeList;
