import { atom } from 'jotai';

export const vehiclePickerOpen = atom<boolean>(false);

export const openVehiclePickerToggle = atom(null, (get, set, by) =>
  set(vehiclePickerOpen, (value) => !value)
);

export const settingsModalOpen = atom<boolean>(false);

export const settingsModalToggle = atom(null, (get, set, by) =>
  set(settingsModalOpen, (value) => !value)
);

export const vehiclePickerAtom = atom<Array<number>>([]);

export const vehiclePickerPhase = atom<
  'Model' | 'Version' | 'Brand' | 'AllSelected' | null
>(null);

export const plateOrKbaForm = atom<boolean>(true);

export const categoryInformation = atom<TCategorySelection | null>(null);
