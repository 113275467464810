import cx from 'classnames';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';

import useIsMobile from '../../../../../hooks/use-is-mobile';
import useBuildList from '../../../../../hooks/user-build-list';
import ListItem from '../../../../modules/v2/vehicle-picker/step-selector/list-item';
import ListSeparator from '../../../../modules/v2/vehicle-picker/step-selector/list-separator';
import FloatScroll from '../../../../ui/v2/float-scroll';
import VehiclePreview from '../../../../ui/vehicle-preview';

import BeforeList from './before-list';
import useTranslation from 'next-translate/useTranslation';
import Button from '../../../../ui/button';
import Collapsable from '../../../../ui/collapsible-list/menu/collapsable';
import Chevrelon from '../../../../ui/icons/cheverlon';
import IconClose from '../../../../ui/icons/close';
import Sticky from 'react-sticky-el';
import SearchByPlateSection from './search-by-plate';

const BrandListAndSearchSection: FunctionComponent<{
  brands: any;
  isMobileView?: boolean;
  selection?: { category: TCategorySelection };
  hasSelection?: any;
  searchByPlateAvailable?: boolean;
  isOpen?: boolean;
  savedCarsAvailable?: boolean;
  hideBrandList?: boolean;
  searchProps: { search: string; setSearch: any };
}> = ({
  brands,
  isMobileView,
  selection = {},
  hasSelection = false,
  searchByPlateAvailable = true,
  isOpen = false,
  savedCarsAvailable = true,
  hideBrandList = false,
  searchProps,
}) => {
  const isMobile = useIsMobile() || isMobileView;
  const numberOfColumnsDesktop = 6;
  const brandsList = useBuildList({
    brands,
    search: searchProps.search,
  });

  const handleOnScroll = () => {
    const fromTop = 0;
    window.scrollTo({
      top: fromTop,
    });
  };

  return (
    <div
      className={cx('hybridBrandsNavigation', {
        '[&_.sticky]:hidden': !isOpen && hideBrandList,
      })}
    >
      <Sticky
        mode="top"
        topOffset={-48}
        boundaryElement=".hybridBrandsNavigation"
        hideOnBoundaryHit={false}
        wrapperClassName={cx(hideBrandList ? '[&.sticky>div]:mt-12' : 'z-10')}
        dontUpdateHolderHeightWhenSticky={true}
      >
        <BeforeList
          search={searchProps.search}
          setSearch={searchProps.setSearch}
          target="categories-with-vehicle"
          isMobileView={isMobile}
          selection={selection}
          hasSelection={hasSelection}
          searchByPlateAvailable={searchByPlateAvailable}
          savedCarsAvailable={savedCarsAvailable}
        />
      </Sticky>
      <SearchByPlateSection
        selection={selection}
        hasSelection={hasSelection}
        searchByPlateAvailable={searchByPlateAvailable}
      ></SearchByPlateSection>
      <section
        className={cx(
          'container',
          'm-auto',
          'grid grid-cols-1 max-lg:text-center max-lg:px-4',
          isMobileView ? 'px-4 scroll-smooth' : 'lg:grid-cols-6'
        )}
      >
        {brandsList.map((elements, index) => {
          const totalOfRows = Math.round(
            brandsList.length / (isMobile ? 1 : numberOfColumnsDesktop)
          );
          const actualRow = Math.abs(
            index / (isMobile ? 1 : numberOfColumnsDesktop)
          );

          return (
            <ol
              key={index}
              className={cx(
                'pt-14 pb-14 ',
                actualRow < (isMobile ? totalOfRows - 1 : totalOfRows)
                  ? 'border-b border-neutral-30'
                  : '',
                'max-lg:first:pt-8'
              )}
            >
              {elements.map(({ type, children }) => {
                if (type === 'brand') {
                  return (
                    <ListItem
                      key={children.brand.id}
                      {...children.link}
                      tabIndex={0}
                    >
                      <VehiclePreview
                        as="h3"
                        info={{
                          brand: children.brand,
                          total_products: children.brand.total_products,
                        }}
                      />
                    </ListItem>
                  );
                } else if (type === 'separator') {
                  return (
                    <ListSeparator key={children.text}>
                      {children.text}
                    </ListSeparator>
                  );
                }
              })}
            </ol>
          );
        })}
      </section>
    </div>
  );
};

const BrandList: FunctionComponent<{
  brands: TBrand[];
  isMobileView?: boolean;
  selection?: { category: TCategorySelection };
  hasSelection?: any;
  searchByPlateAvailable?: boolean;
  isScrollToTopAvailable?: boolean;
  hideBrandList?: boolean;
  defaultisOpenValue?: boolean;
  savedCarsAvailable?: boolean;
}> = ({
  brands,
  isMobileView,
  selection = {},
  hasSelection = false,
  searchByPlateAvailable = true,
  isScrollToTopAvailable = true,
  hideBrandList = false,
  defaultisOpenValue = false,
  savedCarsAvailable = true,
}) => {
  const [search, setSearch] = useState<string>('');
  const { t } = useTranslation();
  const isMobile = useIsMobile() || isMobileView;
  const [isOpen, setisOpen] = useState<boolean>(defaultisOpenValue);

  useEffect(() => {
    setisOpen(!isMobile);
  }, [isMobile]);

  const handleOnScroll = () => {
    const fromTop = 0;
    window.scrollTo({
      top: fromTop,
    });
  };

  return (
    <>
      <section
        className={cx('pt-4 lg:pt-12', { 'pb-6': !isOpen })}
        id="scroll_to_search"
      >
        {hideBrandList ? (
          <>
            <section className="flex items-center">
              <span
                className={cx(
                  'w-full flex flex-col mx-auto text-center items-center justify-center lg:pb-6'
                )}
              >
                <b className="text-xl font-bold lg:pb-3">
                  {t('brands:doYouWantSearchYourVehicle')}
                </b>
                <div className="brandsList w-full lg:px-4">
                  <Sticky
                    mode="top"
                    boundaryElement=".brandsList"
                    hideOnBoundaryHit={false}
                    stickyClassName="z-10"
                  >
                    <div className="w-full h-12 bg-white flex flex-col mx-auto pt-2 text-center items-center justify-center">
                      <Button
                        variant="ROUNDED"
                        onClick={() => {
                          if (isOpen) {
                            setSearch('');
                          }
                          setisOpen(!isOpen);
                          const element = document.querySelector('.brandsList');
                          element?.scrollIntoView({ behavior: 'instant' });
                        }}
                        className={cx(
                          'transition-all duration-300 ease-in-out',
                          'overflow-hidden border-b border-neutral-30 last-of-type:border-0',
                          'flex flex-row items-center justify-center gap-2 !px-4 !py-2',
                          isOpen
                            ? '!bg-neutral-80 !text-white'
                            : '!text-bparts-100'
                        )}
                        isFullWidth={false}
                        disableHeight={true}
                      >
                        <span className="font-bold text-[18px]">
                          {t('brands:searchAllBrands')}
                        </span>
                        {isOpen ? (
                          <IconClose
                            className={cx(
                              'w-4',
                              'transition-transform duration-300 ease-in-out text-white'
                            )}
                          ></IconClose>
                        ) : (
                          <Chevrelon
                            className={cx(
                              'w-4',
                              'transition-transform duration-300 ease-in-out text-bparts-100'
                            )}
                          ></Chevrelon>
                        )}
                      </Button>
                    </div>
                  </Sticky>
                  <Collapsable
                    id={'BrandListAndSearchSection'}
                    content={
                      <BrandListAndSearchSection
                        brands={brands}
                        isMobileView={isMobileView}
                        selection={selection}
                        hasSelection={hasSelection}
                        searchByPlateAvailable={searchByPlateAvailable}
                        isOpen={isOpen}
                        savedCarsAvailable={savedCarsAvailable}
                        hideBrandList={hideBrandList}
                        searchProps={{ search, setSearch }}
                      ></BrandListAndSearchSection>
                    }
                    active={isOpen}
                  />
                </div>
              </span>
            </section>
          </>
        ) : (
          <BrandListAndSearchSection
            brands={brands}
            isMobileView={isMobileView}
            selection={selection}
            hasSelection={hasSelection}
            searchByPlateAvailable={searchByPlateAvailable}
            savedCarsAvailable={savedCarsAvailable}
            hideBrandList={hideBrandList}
            searchProps={{ search, setSearch }}
          ></BrandListAndSearchSection>
        )}
      </section>
      {isMobile && isScrollToTopAvailable && (
        <FloatScroll handleOnScroll={handleOnScroll}></FloatScroll>
      )}
    </>
  );
};

export default BrandList;
